import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="About" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300"></p>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        This is an open source word guessing game -{' '}
        <a
          href="https://github.com/andrewblance/shetland-wordle"
          target="_blank"
          rel="noreferrer"
          className="underline font-bold"
        >
          Take a look at the code from the original author here!
        </a>{' '}
      </p>
    </BaseModal>
  )
}
