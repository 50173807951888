export const GAME_TITLE = 'WURDLE'

export const WIN_MESSAGES = [
  'Weel done beuy!',
  'Correct!',
  'Splendid!',
  'Well done!',
  "You've got it!",
]
export const GAME_COPIED_MESSAGE = 'Game copied to clipboard'
export const ABOUT_GAME_MESSAGE = 'About this game'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Not enough letters'
export const WORD_NOT_FOUND_MESSAGE = 'Word not found'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `The word was ${solution}`
export const ENTER_TEXT = 'Enter'
export const DELETE_TEXT = 'Delete'
export const STATISTICS_TITLE = 'Statistics'
export const GUESS_DISTRIBUTION_TEXT = 'Guess Distribution'
export const NEW_WORD_TEXT = 'New word in'
export const SHARE_TEXT = 'Share'
export const TOTAL_TRIES_TEXT = 'All Tries'
export const SUCCESS_RATE_TEXT = 'Success Rate'
export const CURRENT_STREAK_TEXT = 'Current Streak'
export const BEST_STREAK_TEXT = 'Best streak'
